import React, { useState } from "react";
import Roadmap from "../Roadmap";
import Derivatives from "./Derivatives";
import "./Matematik3c.css";

const Matematik3c = () => {
  // Define the roadmap sections
  const sections = [
    { name: "Matematik 3c Overview", component: <p>Welcome to Matematik 3c!</p> },
    { name: "Derivatives", component: <Derivatives /> },
  ];

  const [currentSection, setCurrentSection] = useState(sections[0]); // Default to the first section

  return (
    <div className="matematik3c-wrapper">
      <Roadmap sections={sections} onSelect={setCurrentSection} />
      <div className="matematik3c-content">
        <h1>{currentSection.name}</h1>
        {currentSection.component}
      </div>
    </div>
  );
};

export default Matematik3c;
