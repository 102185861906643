import React, { useState } from 'react';
import './Popup.css';

const Popup = ({ 
    title, 
    content, 
    showPopup, 
    onClose, 
    mode = 'message', // message, password, confirm, warning, or error
    inputPlaceholder, 
    onConfirm 
}) => {
    const [inputValue, setInputValue] = useState('');

    if (!showPopup) return null;

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleConfirm = () => {
        if (onConfirm) {
            if (mode === 'password') {
                onConfirm(inputValue); // Pass the entered password
            } else {
                onConfirm(); // Handle confirm action (e.g., open password popup)
            }
        }
        setInputValue(''); // Clear input
        onClose(); // Close the popup
    };
    

    return (
        <div className="popup-overlay">
            <div className={`popup-container popup-${mode}`}>
                <button className="popup-close" onClick={onClose}>×</button>
                <h2>{title}</h2>
                <p>{content}</p>
                {mode === 'password' && (
                    <input
                        type="password"
                        placeholder={inputPlaceholder || 'Enter password'}
                        value={inputValue}
                        onChange={handleInputChange}
                        className="popup-input"
                    />
                )}
                <div className="popup-actions">
                    <button onClick={handleConfirm} className="popup-confirm">
                        {mode === 'confirm' ? 'Yes' : 'Confirm'}
                    </button>
                    <button onClick={onClose} className="popup-cancel">
                        {mode === 'confirm' ? 'No' : 'Cancel'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
