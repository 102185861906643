import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom"; // Import useNavigate
import './ForgotPassword.css';
import StudlyLogo from './Icons/StudlyLogo.svg';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [newPassword, setNewPassword] = useState("");
    const navigate = useNavigate(); // Hook for navigation

    const handleForgotPassword = async () => {
        setMessage("");
        setError("");

        try {
            const response = await fetch("https://www.studly.nu/forgot_password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
            } else {
                setError(data.error || "Failed to send reset email.");
            }
        } catch (err) {
            setError("An error occurred. Please try again later.");
        }
    };

    const handleResetPassword = async () => {
        setMessage("");
        setError("");

        if (!token || !newPassword) {
            setError("Token or new password missing.");
            return;
        }

        try {
            const response = await fetch("https://www.studly.nu/reset_password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token, new_password: newPassword }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
            } else {
                setError(data.error || "Failed to reset password.");
            }
        } catch (err) {
            setError("An error occurred. Please try again later.");
        }
    };

    if (token) {
        return (
            <div className="forgot-password">
                <div className="back-arrow" onClick={() => navigate('/login')}>
                    ← Back to Login
                </div>
                <div className="forgot-password-logo">
                    <img src={StudlyLogo} alt="Studly" className="logo-icon-forgot-password" />
                </div>
                <h2 className="forgot-password-heading">Reset Password</h2>
                {message && <p className="forgot-password-message">{message}</p>}
                {error && <p className="forgot-password-error">{error}</p>}
                <form onSubmit={(e) => { e.preventDefault(); handleResetPassword(); }}>
                    <label className="forgot-password-label">
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </label>
                    <button type="submit" className="forgot-password-button">Reset Password</button>
                </form>
            </div>
        );
    }

    return (
        <div className="forgot-password">
            <div className="back-arrow" onClick={() => navigate('/login')}>
                ← Tillbaka
            </div>
            <div className="forgot-password-logo">
                <img src={StudlyLogo} alt="Studly" className="logo-icon-forgot-password" />
            </div>
            <h2 className="forgot-password-heading">Glömt Lösenordet</h2>
            <p id="informationText">
                Skriv in den E-post du registrerade med och följ instruktionerna för att återställa lösenordet.
            </p>
            {message && <p className="forgot-password-message">{message}</p>}
            {error && <p className="forgot-password-error">{error}</p>}
            <form onSubmit={(e) => { e.preventDefault(); handleForgotPassword(); }}>
                <label className="forgot-password-label">
                    <input
                        type="email"
                        placeholder="E-post"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <button type="submit" className="forgot-password-button">Gå vidare</button>
            </form>
        </div>
    );
};

export default ForgotPassword;
