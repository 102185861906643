import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './header.css';
import StudlyLogo from './Icons/StudlyLogoMain.svg';

function Header({ currentUser, handleLogout }) {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const scrollToPricing = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        if (location.pathname === '/') {
            const pricingSection = document.getElementById('pricing-section');
            if (pricingSection) {
                pricingSection.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Redirect to home and delay scrolling to pricing
            window.location.href = '/';
            setTimeout(() => {
                const pricingSection = document.getElementById('pricing-section');
                if (pricingSection) {
                    pricingSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500); // Adjust delay as needed
        }
    };

    return (
        <header className="header">
            <div className="header-container">
                <div className="header-logo">
                    <Link to="/">
                        <img src={StudlyLogo} alt="Studly" className="logo-icon" />
                    </Link>
                </div>
                <div className="header-right">
                    <nav className="header-nav">
                        {!currentUser && <Link to="/">Hem</Link>}
                        {currentUser && <Link to="/Overview">Överblick</Link>}
                        <Link to="/courses">Kurser</Link>
                        <Link to="/Merit">Räkna ut Merit</Link>
                        <Link to="#" onClick={scrollToPricing}>Studly Pro</Link>
                        {currentUser && <Link to="/profile">Profil</Link>}
                    </nav>
                    <div className="header-login">
                        {currentUser ? (
                            <button onClick={handleLogout} className="login-button">Profil</button>
                        ) : (
                            <Link to="/login" className="login-button-header">Logga in</Link>
                        )}
                    </div>
                    {/* Menu Icon for small screens */}
                    <div className="menu-icon" onClick={toggleMenu}>
                        <i className="material-icons">menu</i>
                    </div>
                </div>
            </div>
            {/* Overlay */}
            {menuOpen && <div className="overlay" onClick={toggleMenu}></div>}
            {/* Slide-out menu for small screens */}
            <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
                <nav className="mobile-nav">
                    {!currentUser && <Link to="/" onClick={toggleMenu}>Hem</Link>}
                    {currentUser && <Link to="/Overview" onClick={toggleMenu}>Överblick</Link>}
                    <Link to="/courses" onClick={toggleMenu}>Kurser</Link>
                    <Link to="/Merit" onClick={toggleMenu}>Räkna ut Merit</Link>
                    <Link to="#" onClick={(e) => { scrollToPricing(e); toggleMenu(); }}>Studly Pro</Link>
                    {currentUser && <Link to="/profile" onClick={toggleMenu}>Profil</Link>}
                    {currentUser ? (
                        <button onClick={() => { handleLogout(); toggleMenu(); }} className="login-button">Logga ut</button>
                    ) : (
                        <Link to="/login" onClick={toggleMenu} className="login-button-header">Logga in</Link>
                    )}
                </nav>
            </div>
        </header>
    );
}

export default Header;
