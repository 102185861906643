import React from 'react';
import { Link } from 'react-router-dom';

const Courses = () => {
    const courses = [
        { name: 'Matematik 1a', path: '/courses/Matematik1a' },
        { name: 'Matematik 3c', path: '/courses/Matematik3c' },
        { name: 'Matematik 4', path: '/courses/Matematik4' }
    ];

    return (
        <div className="courses-container">
            <h2>Kurser:</h2>
            <ul>
                {courses.map((course, index) => (
                    <li key={index}>
                        <Link to={course.path}>{course.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Courses;
