import React from 'react';

const Matematik1a = () => {
    return (
        <div className="course-content">
            <h2>Matematik 1a</h2>
            <p>Welcome to Matematik 1a. This course covers the fundamentals of algebra, geometry, and basic calculus to prepare students for advanced mathematics.</p>
        </div>
    );
};

export default Matematik1a;