import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Register.css';
import Popupdiscrete from './Popupdiscrete';
import StudlyLogo from './Icons/StudlyLogo.svg';

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [popupMessage, setPopupMessage] = useState(null); // For displaying messages
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setPopupMessage({ message: 'Passwords do not match', type: 'error' });
            return;
        }

        fetch('https://www.studly.nu/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, email, password }),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    setPopupMessage({ message: data.message, type: 'success' });
                    // Redirect to /verify_code with the email passed as state
                    setTimeout(() => navigate('/verify_code', { state: { email } }), 2000);
                } else {
                    setPopupMessage({ message: data.error, type: 'error' });
                }
            })
            .catch(() => {
                setPopupMessage({ message: 'Registration failed. Please try again.', type: 'error' });
            });
    };

    const handleGoogleLogin = () => {
        window.location.href = 'https://www.studly.nu/login/google';
    };

    return (
        <motion.div
            className="login"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
        >
            <div className="register-logo">
                <img src={StudlyLogo} alt="Studly" className="logo-icon-login" />
            </div>
            <h2 className="register-heading">Bli medlem</h2>
            <form onSubmit={handleSubmit}>
                <label className="register-label">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Användarnamn"
                        required
                    />
                </label>
                <label className="register-label">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-post"
                        required
                    />
                </label>
                <label className="register-label">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Lösenord"
                        required
                    />
                </label>
                <label className="register-label">
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Bekräfta Lösenord"
                        required
                    />
                </label>
                <button type="submit" className="register-button">
                    Gå vidare
                </button>
            </form>

            <button onClick={handleGoogleLogin} className="google-login-button">
                Logga in med Google
            </button>

            <p className="login-register">
                Har du ett konto? <Link to="/login">Logga in</Link>
            </p>

            {popupMessage && (
                <Popupdiscrete
                    message={popupMessage.message}
                    type={popupMessage.type}
                    onClose={() => setPopupMessage(null)}
                />
            )}
        </motion.div>
    );
}

export default Register;
