import React from "react";

const Derivatives = () => {
  return (
    <div>
      <p>Learn about derivatives and their applications in optimization, rates of change, and curve sketching.</p>
    </div>
  );
};

export default Derivatives;
