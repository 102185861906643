import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Popupdiscrete.css';

const Popupdiscrete = ({ message, type = 'info', duration = 3000, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Set a timer to trigger the fade-out
        const timer = setTimeout(() => {
            setVisible(false); // Trigger exit animation
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    // Handle unmount after animation
    const handleAnimationComplete = () => {
        if (!visible) {
            onClose(); // Only call onClose after fade-out
        }
    };

    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    className={`popup-discrete popup-discrete-${type}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    onAnimationComplete={handleAnimationComplete} // Hook to handle unmounting
                >
                    {message}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Popupdiscrete;
