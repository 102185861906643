import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import Popupdiscrete from './Popupdiscrete';
import Loading from './Loading';

const Profile = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [rank, setRank] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [editField, setEditField] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({});
    const [popupMessage, setPopupMessage] = useState(null); // For Popupdiscrete
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        // Fetch current user data
        fetch('https://www.studly.nu/current_user', {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.username) {
                    fetch('https://www.studly.nu/get_user_data', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        credentials: 'include',
                        body: JSON.stringify({ username: data.username }),
                    })
                        .then((response) => response.json())
                        .then((userData) => {
                            setUsername(userData.username);
                            setEmail(userData.email);
                            setRank(userData.rank);
                            setLoading(false); // Hide loading after data is fetched
                        })
                        .catch(() => {
                            setPopupMessage({ message: 'Error fetching user data', type: 'error' });
                            setLoading(false);
                        });
                } else {
                    setLoading(false); // Hide loading if no user is logged in
                }
            })
            .catch(() => {
                setPopupMessage({ message: 'Error fetching current user', type: 'error' });
                setLoading(false);
            });
    }, []);

    const handleUpdate = (field) => {
        const updatedData = {};

        if (field === 'username') {
            updatedData.username = username;
        } else if (field === 'email') {
            updatedData.email = email;
        } else if (field === 'password') {
            updatedData.new_password = newPassword;
        }

        fetch('https:///www.studly.nu/update_profile', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(updatedData),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message) {
                    setPopupMessage({ message: 'Profile updated successfully', type: 'success' });
                    setEditField(null);
                } else {
                    setPopupMessage({ message: data.error, type: 'error' });
                }
            })
            .catch(() => {
                setPopupMessage({ message: 'Error updating profile', type: 'error' });
            });
    };

    const handlePasswordPopup = () => {
        setPopupData({
            title: 'Enter Current Password',
            content: 'Please enter your current password to proceed.',
            mode: 'password',
            onConfirm: (currentPassword) => handleUpdateWithPassword(currentPassword),
        });
        setShowPopup(true);
    };

    const handleUpdateWithPassword = (currentPassword) => {
        const updatedData = {
            current_password: currentPassword,
            new_password: newPassword,
        };

        fetch('https:///www.studly.nu/update_profile', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(updatedData),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message) {
                    setPopupMessage({ message: 'Password updated successfully', type: 'success' });
                    setEditField(null);
                } else {
                    setPopupMessage({ message: data.error, type: 'error' });
                }
            })
            .catch(() => {
                setPopupMessage({ message: 'Error updating password', type: 'error' });
            });
    };

    const handleDownloadData = (currentPassword) => {
        fetch('https:///www.studly.nu/download_user_data', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include', // Include session cookies
            body: JSON.stringify({ current_password: currentPassword }),
        })
            .then((response) => {
                if (!response.ok) throw new Error('Failed to download user data');
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'user_data.json';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url); // Clean up the object URL
            })
            .catch(() => {
                setPopupMessage({
                    message: 'Error downloading user data. Please check your password.',
                    type: 'error',
                });
            });
    };


    const handleDeleteAccount = (currentPassword) => {
        console.log('Deleting account with password:', currentPassword);
        fetch('https:///www.studly.nu/delete_account', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ current_password: currentPassword }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    console.log('Account deletion success:', data.message);
                    setPopupMessage({ message: 'Account deleted successfully', type: 'success' });
                    setTimeout(() => {
                        window.location.href = '/'; // Redirect after deletion
                    }, 2000);
                } else {
                    console.log('Account deletion error:', data.error);
                    setPopupMessage({ message: data.error, type: 'error' });
                }
            })
            .catch((error) => {
                console.error('Error deleting account:', error);
                setPopupMessage({ message: 'Error deleting account. Please try again.', type: 'error' });
            });
    };



    return (
        <div className="profile-container">
            {loading && <Loading />}

            {!loading && (
                <>
                    <h2>Profil</h2>
                    <div className="profile-field">
                        <label>Användarnamn:</label>
                        {editField === 'username' ? (
                            <>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <button onClick={() => handleUpdate('username')}>Apply</button>
                            </>
                        ) : (
                            <>
                                <span>{username}</span>
                                <button onClick={() => setEditField('username')}>Edit</button>
                            </>
                        )}
                    </div>
                    <div className="profile-field">
                        <label>Email:</label>
                        {editField === 'email' ? (
                            <>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button onClick={() => handleUpdate('email')}>Apply</button>
                            </>
                        ) : (
                            <>
                                <span>{email}</span>
                                <button onClick={() => setEditField('email')}>Edit</button>
                            </>
                        )}
                    </div>
                    <div className="profile-field">
                        <label>Change Password:</label>
                        {editField === 'password' ? (
                            <>
                                <input
                                    type="password"
                                    placeholder="New password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <button onClick={handlePasswordPopup}>Apply</button>
                            </>
                        ) : (
                            <button onClick={() => setEditField('password')}>Change Password</button>
                        )}
                    </div>
                    <div className="profile-field">
                        <span>{rank}</span>
                    </div>
                    <button
                        className="download-btn"
                        onClick={() =>
                            setPopupData({
                                title: 'Enter Password',
                                content: 'Please enter your password to download your data.',
                                mode: 'password',
                                onConfirm: (password) => handleDownloadData(password),
                            }) || setShowPopup(true)
                        }
                    >
                        Download User Data
                    </button>

                    <button
                        className="delete-account-btn"
                        onClick={() => {
                            setPopupData({
                                title: 'Confirm Account Deletion',
                                content: 'Are you sure you want to delete your account? This action cannot be undone.',
                                mode: 'confirm',
                                onConfirm: () => {
                                    console.log('Confirmation step passed. Showing password popup...');
                                    setPopupData({
                                        title: 'Enter Password',
                                        content: 'Please enter your password to delete your account.',
                                        mode: 'password',
                                        onConfirm: (password) => handleDeleteAccount(password),
                                    });
                                    setTimeout(() => setShowPopup(true), 0); // Ensure popup rendering
                                },
                            });
                            setShowPopup(true); // Show confirmation popup
                        }}
                    >
                        Delete Account
                    </button>



                    {/* Popup for password input */}
                    <Popup
                        {...popupData}
                        showPopup={showPopup}
                        onClose={() => setShowPopup(false)}
                    />

                    {/* Popupdiscrete for success/error messages */}
                    {popupMessage && (
                        <Popupdiscrete
                            message={popupMessage.message}
                            type={popupMessage.type}
                            onClose={() => setPopupMessage(null)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Profile;
