import React from "react";
import "./Roadmap.css";

const Roadmap = ({ sections, onSelect }) => {
  return (
    <div className="roadmap-container">
      <h2>Course Roadmap</h2>
      <ul>
        {sections.map((section, index) => (
          <li key={index} onClick={() => onSelect(section)}>
            {section.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Roadmap;
