import React from 'react';

const Matematik4 = () => {
    return (
        <div className="course-content">
            <h2>Matematik 4</h2>
            <p>Welcome to Matematik 4. This course covers advanced calculus topics, including derivatives, integrals, and differential equations, essential for higher-level studies in mathematics and engineering.</p>
        </div>
    );
};

export default Matematik4;
