import React, { useState } from 'react';

const Merit = () => {
    const [grades, setGrades] = useState(['']);
    const [meritValue, setMeritValue] = useState(null);

    const handleGradeChange = (index, value) => {
        const newGrades = [...grades];
        newGrades[index] = value;
        setGrades(newGrades);
    };

    const addGradeField = () => {
        setGrades([...grades, '']);
    };

    const calculateMerit = () => {
        const gradePoints = grades.map(grade => {
            switch (grade.toUpperCase()) {
                case 'A': return 20;
                case 'B': return 17.5;
                case 'C': return 15;
                case 'D': return 12.5;
                case 'E': return 10;
                case 'F': return 0;
                default: return 0;
            }
        });

        const totalPoints = gradePoints.reduce((acc, point) => acc + point, 0);
        const merit = totalPoints / gradePoints.length;
        setMeritValue(merit.toFixed(2));
    };

    return (
        <div className="merit-container">
            <h2>Calculate Your Merit Value</h2>
            {grades.map((grade, index) => (
                <div key={index} className="grade-input">
                    <label>Grade {index + 1}:</label>
                    <input
                        type="text"
                        value={grade}
                        onChange={(e) => handleGradeChange(index, e.target.value)}
                        placeholder="Enter A-F"
                    />
                </div>
            ))}
            <button onClick={addGradeField}>Add Grade</button>
            <button onClick={calculateMerit}>Calculate Merit Value</button>

            {meritValue !== null && (
                <div className="result">
                    <h3>Your Merit Value: {meritValue}</h3>
                </div>
            )}
        </div>
    );
};

export default Merit;
