import React, { useState, useEffect } from 'react';
import './Overview.css';

const Overview = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://www.studly.nu/get_user_results', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user results");
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setResults(data.results);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading results...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="quiz-results-container">
      <h1>Quiz Results Overview</h1>
      {results.length > 0 ? (
        <table className="quiz-results-table">
          <thead>
            <tr>
              <th>Quiz Name</th>
              <th>Score</th>
              <th>Correct Answers</th>
              <th>Incorrect Answers</th>
              <th>Date Taken</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                <td data-label="Quiz Name">{result.quiz_name}</td>
                <td data-label="Score">{result.score}</td>
                <td data-label="Correct Answers">{result.correct_answers}</td>
                <td data-label="Incorrect Answers">{result.incorrect_answers}</td>
                <td data-label="Date Taken" className="date-cell">{new Date(result.test_date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No quiz results found.</p>
      )}
    </div>
  );
};

export default Overview;
